import {
  CAAS_INDEX_SUFFIX,
  DISABLE_ADS,
  IS_DEV_SITE,
} from "@src/app/constants";
import {
  GA4_REF_CODE_CONTENTBLOCK_B,
  GA4_REF_CODE_CONTENTBLOCK_E,
  GA4_REF_CODE_CONTENTBLOCK_F,
  GA4_REF_CODE_CONTENTBLOCK_H,
} from "@src/app/utils/ga/ref-code";
import {
  BRIGHTCOVE_CONFIG,
  DEFAULT_CONFIG_GAM,
  LOTAME_CONFIG,
  PREMUTIVE_CONFIG,
} from "@src/pubs/common/config";

import {
  ConfigAboutUs,
  ConfigAffiliatesDeclaration,
  ConfigAmazonAffiliates,
  ConfigAuthorProfile,
  ConfigBrightcove,
  ConfigCaas,
  ConfigCategory,
  ConfigContentHub,
  ConfigError,
  ConfigExploreMore,
  ConfigGAM,
  ConfigGTM,
  ConfigHome,
  ConfigLatestStory,
  ConfigLotame,
  ConfigNewsletter,
  ConfigPermutive,
  ConfigSearch,
  ConfigSingleStory,
  ConfigSite,
  ConfigSkimlinks,
  ConfigTag,
  ConfigTeaser,
  ConfigTrackingCode,
  ConfigVideoLanding,
} from "../types/config";
import * as Icons from "./config/icons/pageShareIcons";
import { readmoreIcon } from "./icons/readmoreIcon";
import logoPath from "./logo.svg?url";
import newsletterBg from "./newsletterbg.png";
import newsletterSignupBg from "./newslettersignupbg.jpg";

export const configSite: ConfigSite = {
  name: "Home & Decor Singapore",
  address:
    import.meta.env.VITE_PUBLIC_BASE_URL || "https://www.homeanddecor.com.sg/", // without trailing slash
  tagline:
    "HomeandDecor.com.sg is an interactive, style-driven interior design website for new home owners, renovation professionals and furnishing enthusiasts.",
  description:
    "Singapore's top design magazine featuring exclusive designer interviews, home styling tips, home appliance + product reviews and interior decor showcases of beautiful HDB, condo and private homes from SG and around the world.",
  titleSeparator: "—",
  logoPath,
  pageShare: {
    media: [
      {
        name: "Facebook",
        icon: Icons.facebookIcon,
      },
      {
        name: "X",
        icon: Icons.xIcon,
      },
      {
        name: "Whatsapp",
        icon: Icons.whatsappIcon,
      },
      {
        name: "Pinterest",
        icon: Icons.pinterestIcon,
      },
      {
        name: "Email",
        icon: Icons.emailIcon,
      },
    ],
  },
};

export { configHeader } from "./config/configHeader";

export const configCaas: ConfigCaas = {
  index: `homedecor${CAAS_INDEX_SUFFIX}`,
  tagIdPrefix: "tag:homedecor-keywords@sph.com.sg,2023:",
  cuePublicationName: "homedecor",
};

export const configGTM: ConfigGTM = {
  container: "GTM-TGTBWS6", //HND
  siteVerification: "_cGEdj2ZMeQhb_OTIAKvlCGbSxaBwct6M6XrDxywcXM",
};

export const configGAM: ConfigGAM = {
  ...DEFAULT_CONFIG_GAM,
  account: "/5908/hnd-sg",
};

export const configLotame: ConfigLotame = {
  ...LOTAME_CONFIG,
  active: !DISABLE_ADS,
};

export const configPermutive: ConfigPermutive = {
  ...PREMUTIVE_CONFIG,
  active: !DISABLE_ADS,
};

export const configSkimlinks: ConfigSkimlinks = {
  active: !DISABLE_ADS,
  skimlinkId: "157725X1632720",
  geniuslinkId: "319906",
};

export const configBrightcove: ConfigBrightcove = {
  ...BRIGHTCOVE_CONFIG,
};

export const configSingleStory: ConfigSingleStory = {
  byline: {
    defaultCredit: "Home & Decor Team",
  },
  configShareArticle: {
    label: "Share this article",
    divider: {
      top: false,
    },
  },
  topDivider: true,
  gallery: {
    paginationSeparator: "/",
  },
  featureStory: {
    bodyMaxWidth: 890,
  },
  authorProfile: true,
  displayTags: {
    displayTopTags: false,
    displayTagHeading: true,
  },
};

export const configLatestStory: ConfigLatestStory = {
  latestStoryLimit: 21,
  subAnchorLimit: 3,
  landscapeIndexes: [0, 1, 2],
  mainContentBlockDisplay: "grid",
  mainContentBlockMode: "landscape",
  errorLayoutMode: "landscape",
  portraitImageWidth: 285,
  landscapeImageWidth: 345,
};

export const configCategory: ConfigCategory = {
  pinnedTeasersLimit: 3,
  latestStoriesLimit: 8,
  videoGalleryLandscape: {
    mobileNavigation: true,
    display: true,
  },
  dividerVariant: "secondary",
};

export const configContentHub: ConfigContentHub = {
  configMasthead: {
    heading: {
      display: true,
    },
    gradientOverlay: {
      display: true,
    },
  },
  configBodyContent: {
    display: true,
  },
  configShareButton: {
    display: false,
  },
  configEditorsNote: {
    display: false,
  },
  configCallQoute: {
    display: true,
  },
  configFeaturedSection: {
    display: true,
  },
  configDeals: {
    display: true,
    props: {
      heading: "editor's picks",
      noOfItemsInFrame: 4,
    },
  },
  configTopStories: {
    display: true,
    listingPerPage: 4,
  },
  storiesPerPage: {
    topStories: 4,
    numberOfStories: 8,
  },
  configListingStories: {
    mode: "landscape",
  },
  contenthubBlockF: {
    mode: "landscape",
  },
  layout: {
    stories: {
      elements: [
        {
          name: "contentBlockF",
          from: 0,
          limit: 4,
        },
        { name: "adsMidContent" },
      ],
    },
  },
};

export const configTag: ConfigTag = {
  topStoriesLimit: 0,
  listedStoriesLimit: 12,
  itemSeparator: ", ",
};

export const configAmazonAffiliates: ConfigAmazonAffiliates = {
  partnerTag: IS_DEV_SITE ? "sphmedia-dev-22" : "sphmedia-homeanddecor-22",
};

export const configSearch: ConfigSearch = {
  loadMoreText: "Loading more results",
};

export const configExploreMore: ConfigExploreMore = {
  heading: "Explore other topics",
};

export const configVideoLanding: ConfigVideoLanding = {};

export const configHome: ConfigHome = {
  anchorBlock: {
    hideBreadCrumb: true,
  },
  isHeadingClickable: true,
  headingDivider: false,
  viewMore: { icon: readmoreIcon },
  latestArticle: {
    mobileModeOriginal: false,
    landscapeIndexes: [0, 1, 2, 3],
    viewMore: {
      text: "READ MORE",
    },
  },
  contentBlockF: {
    skipSectionContext: false,
    landscapeIndexes: [0, 1, 2],
    layoutTwoColumn: [1, 2],
    anchorBlock: {
      index: 0,
      mediaScreen: "all-screen",
    },
    landscapeImageWidth: 630,
    twoColumnsLandscapeImageWidth: 300,
    userSubmissionBox: true,
  },
  contentBlockB: {
    landscapeIndexes: [0, 1, 2, 3, 4, 5],
    scrollable: true,
  },
  contentBlockG: {
    landscapeIndexes: [0, 1, 2, 3],
    landscapeImageWidth: 300,
    portraitImageWidth: 300,
  },
  contentBlockD: {
    descriptionInTitle: true,
    crop: "landscape",
  },
  layoutGrid: {
    elements: [
      {
        name: "anchorBlock",
        dataSource: {
          type: "list",
          key: "anchorblock",
          fallback: "latest",
          limit: 1,
        },
      },
      {
        name: "latestArticle",
        dataSource: {
          type: "list",
          key: "pinned",
          fallback: "latest",
          limit: 4,
        },
      },
      {
        name: "contentBlockF",
        dataSource: {
          type: "section",
          key: "interior-design",
          refCode: "hometours",
          limit: 3,
        },
      },
      { name: "adUnit" },
      {
        name: "contentBlockB",
        dataSource: {
          type: "tag",
          key: "topic-0",
          refCode: "contenthub-a",
          min: 3,
          limit: 6,
        },
        alternateBg: true,
      },
      {
        name: "contentBlockG",
        dataSource: {
          type: "section",
          key: "shopping",
          refCode: "block-d",
          limit: 4,
        },
      },
      {
        name: "contentBlockB",
        dataSource: {
          type: "tag",
          key: "topic-1",
          refCode: "contenthub-b",
          limit: 3,
          min: 3,
        },
        alternateBg: true,
      },
      {
        name: "contentBlockF",
        dataSource: {
          type: "section",
          key: "property",
          refCode: "block-c",
          limit: 3,
        },
      },
      {
        name: "contentBlockD",
        dataSource: {
          type: "list",
          key: "trending",
          fallback: "trending",
          refCode: "trending",
          limit: 3,
        },
      },
      {
        name: "affiliateProduct",
        dataSource: { type: "list", key: "deals" },
      },
      {
        name: "contentBlockD",
        dataSource: {
          type: "section",
          key: "how-to",
          refCode: "howto",
          limit: 3,
        },
      },
      // {
      //   name: "socialVideoPortrait",
      //   dataSource: { type: "list", key: "socialvideos" },
      // },
      { name: "magazineListing" },
      { name: "newsletter" },
    ],
  },
  magazineListing: {
    buttonVariant: "primary",
  },
  newsletter: {
    containerImageBg: newsletterBg,
    newsletterTitle: "Your Weekly Home Fix",
    newsletterBody:
      "Inspiring homes, decor ideas, shopping reviews, property news, home financing tips and ‘How To’ hacks.",
  },
  trendingStoriesPublisher: "homeanddecor",
};

export const configNewsletter: ConfigNewsletter = {
  image: newsletterSignupBg,
  title: "Your Weekly Home Fix",
  body: "Inspiring homes, decor ideas, shopping reviews, property news, home financing tips and ‘How To’ hacks.",
};

export const configTeaser: ConfigTeaser = {
  publishDate: false,
  anchorBlock: {
    mobileCrop: "landscape",
  },
};

export const configTrackingCode: ConfigTrackingCode = {
  home: {
    topic: ["contenthub-a", "contenthub-b"],
    trending: "trending",
    blockg: "block-d",
    blockf: ["hometours", "block-c"],
    blockd: "howto",
  },
  category: {
    mainContentBlock: GA4_REF_CODE_CONTENTBLOCK_H,
  },
  exploremore: "exploremore",
  contenthub: {
    topstories: {
      contentBlockB: GA4_REF_CODE_CONTENTBLOCK_B,
      contentBlockF: GA4_REF_CODE_CONTENTBLOCK_F,
    },
    listing: GA4_REF_CODE_CONTENTBLOCK_E,
  },
  customSection: ["hometours", "howto"],
};

export const configAffiliatesDeclaration: ConfigAffiliatesDeclaration = {
  text: "This article contains affiliate links. If you buy through these links, we may earn a small commission.",
};

export const configAboutUs: ConfigAboutUs = {
  profileBlock: {
    heading: "Meet The Team",
  },
};

export const configError: ConfigError = {};

export const configAuthorProfile: ConfigAuthorProfile = {};
